import React from "react";
import { Base, Text } from "../styles";
import styled from "styled-components";
const LeftTriangle = styled(Base)`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 7px 0;
  cursor: ${({ enabled }) => {
  return !enabled ? "not-allowed" : "pointer";
}};
  transition: all 0.2s linear;
  border-color: transparent white transparent transparent;
  opacity: ${({ enabled }) => {
  return !enabled ? "0.3" : 1;
}};

  ${({ enabled }) => {
  return enabled && `
  &:hover {
    border-color: transparent black transparent transparent;
  }`;
}}
`;
const RightTriangle = styled(LeftTriangle)`
  transform: rotate(-180deg);
`;
function mod(a, n) {
  return (a % n + n) % n;
}
export default (props) => {
  const { engines, onNext, onPrev, selectedEngine, loop } = props;
  const currentPage = engines.findIndex((e) => e.slug === selectedEngine.slug);
  const nextEngine = engines[mod(currentPage + 1, engines.length)];
  const prevEngine = engines[mod(currentPage - 1, engines.length)];
  return /* @__PURE__ */ React.createElement(Base, {
    zIndex: 2,
    position: "relative",
    as: "nav",
    bg: "red",
    height: "48px !important",
    px: "20px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "center",
    style: {
      justifyItems: "center"
    }
  }, prevEngine && (loop || currentPage !== 0) && /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    justifySelf: "left",
    minWidth: "175px",
    cursor: "pointer",
    onClick: () => {
      if (loop) {
        onPrev(prevEngine);
      } else if (currentPage !== 0) {
        onPrev(prevEngine);
      }
    }
  }, /* @__PURE__ */ React.createElement(Text, {
    color: "white",
    variant: "small"
  }, "PREVIOUS"), /* @__PURE__ */ React.createElement(Text, {
    color: "white",
    variant: "small-bold",
    display: ["none", null, "initial"]
  }, ":\xA0", prevEngine.engineName)), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    ml: 20,
    gridColumn: "2",
    display: "flex",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Text, {
    color: "white",
    variant: "small-bold",
    mr: 20,
    minWidth: "70px",
    display: ["none", null, "initial"]
  }, selectedEngine.engineName), /* @__PURE__ */ React.createElement(LeftTriangle, {
    as: "div",
    enabled: loop ? true : currentPage !== 0,
    onClick: () => {
      if (loop) {
        onPrev(prevEngine);
      } else if (currentPage !== 0) {
        onPrev(prevEngine);
      }
    }
  }), /* @__PURE__ */ React.createElement(Text, {
    color: "white",
    variant: "small",
    mx: "8px"
  }, currentPage + 1, "/", engines.length), /* @__PURE__ */ React.createElement(RightTriangle, {
    gridColumn: "2",
    as: "div",
    enabled: loop ? true : currentPage !== engines.length - 1,
    onClick: () => {
      if (loop) {
        onNext(nextEngine);
      } else if (currentPage !== engines.length - 1) {
        onNext(nextEngine);
      }
    }
  })), nextEngine && (loop || currentPage !== engines.length - 1) && /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    justifySelf: "right",
    textAlign: "end",
    minWidth: "130px",
    cursor: "pointer",
    onClick: () => {
      if (loop) {
        onNext(nextEngine);
      } else if (currentPage !== engines.length - 1) {
        onNext(nextEngine);
      }
    }
  }, /* @__PURE__ */ React.createElement(Text, {
    color: "white",
    variant: "small"
  }, "NEXT"), /* @__PURE__ */ React.createElement(Text, {
    color: "white",
    variant: "small-bold",
    display: ["none", null, "initial"]
  }, ":\xA0", nextEngine.engineName)));
};
