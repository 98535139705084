import { useState, useRef, useEffect } from "react";
const loadImage = (src, done) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      done();
      resolve(true);
    };
    img.onerror = () => reject(false);
  });
};
export default (paths) => {
  const loadCount = useRef(0);
  const [totalLoaded, setTotalLoaded] = useState(0);
  useEffect(() => {
    paths.map((path) => {
      return loadImage(path, () => {
        loadCount.current++;
        setTotalLoaded(loadCount.current);
      });
    });
  }, []);
  return {
    totalLoaded,
    total: paths.length,
    progress: Math.floor(totalLoaded / paths.length * 100)
  };
};
