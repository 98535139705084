var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createRef
} from "react";
import { Base } from "../../styles";
import { useStaticQuery, graphql } from "gatsby";
import CarouselNav from "../CarouselNav";
import BackgroundEngine from "../../images/engine-background.svg";
import LoadingScreen from "../LoadingScreen";
import EngineViewer from "../EngineViewer";
import EngineSummary from "./EngineSummary";
import Page from "../Page";
import memoize from "memoize-one";
import { gsap } from "gsap";
import useKeyPress from "react-use-keypress";
import MobileEngineDescription from "./MobileEngineDescription";
import useViewportHeight from "../../hooks/useViewportHeight";
import useEngineImageLoadProgress from "./useEngineImageLoadProgress";
const findEngineIndex = (arr, id) => arr.findIndex((x) => x.id === id);
function mod(a, n) {
  return (a % n + n) % n;
}
const Engines = (props) => {
  const {
    setLoadingProgress,
    slideDuration = 0.8,
    easing = "power1.out"
  } = props;
  const { engines, images } = useStaticQuery(graphql`
    query EngineData {
      engines: allEnginesJson(
        sort: {order: ASC, fields: order}
        filter: {enabled: {eq: true}}
      ) {
        nodes {
          ...EnginesFragment
        }
      }
      images: allFile(
        filter: {
          sourceInstanceName: {eq: "images"}
          relativeDirectory: {regex: "/^engines/"}
          name: {regex: "/\\d[1,3,5,7,9]$/"}
        }
      ) {
        nodes {
          relativeDirectory
          sourceInstanceName
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
  `);
  const timeline = useMemo(() => {
    return gsap.timeline();
  }, []);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedEngine, setSelectedEngine] = useState(engines.nodes[0]);
  const { progress } = useEngineImageLoadProgress();
  const slideRefs = engines.nodes.map(() => createRef());
  const [activeSlide, setActiveSlide] = useState(0);
  const pressedKey = useKeyPress("code");
  const [isMobileDescriptionOpen, setIsMobileDescriptionOpen] = useState(false);
  const viewportHeight = useViewportHeight();
  const getEngineFromState = useCallback(() => {
    const a = engines.nodes;
    const b = props.location.state.engine.id;
    const c = findEngineIndex(a, b);
    setSelectedEngine(engines.nodes[c]);
  }, []);
  useEffect(() => {
    setLoadingProgress(progress);
    progress === 100 && setTimeout(() => setIsLoaded(true), 600);
  }, [progress]);
  useEffect(() => {
    var _a, _b;
    if (/\/engines\/\w+/.test((_b = (_a = props.location) == null ? void 0 : _a.state) == null ? void 0 : _b.prevPath))
      getEngineFromState();
  }, []);
  useKeyPress("ArrowRight", () => {
    if (activeSlide < engines.nodes.length - 1) {
      goToNextEngine();
    }
  });
  useKeyPress("ArrowLeft", () => {
    if (activeSlide > 0) {
      goToPreviousEngine();
    }
  });
  const getDesiredActiveSlide = (desiredIndex) => {
    return mod(desiredIndex, engines.nodes.length);
  };
  useEffect(() => {
    if (!isLoaded)
      return;
    const onLoadAnimationDuration = 1;
    const firstSlideimage = slideRefs[0].current.image.current;
    gsap.fromTo(firstSlideimage, {
      transform: "scale(1.8)",
      duration: onLoadAnimationDuration
    }, {
      transform: "scale(1)",
      duration: onLoadAnimationDuration
    });
    const secondSlide = slideRefs[1].current.wrapper.current;
    gsap.from(secondSlide, {
      x: "150px",
      duration: onLoadAnimationDuration - 0.3,
      delay: 0.3
    });
  }, [isLoaded]);
  const getImagesBySlug = memoize((slug) => {
    return images.nodes.reduce((final, {
      relativeDirectory,
      childImageSharp
    }) => {
      if (!relativeDirectory.includes(slug)) {
        return final;
      }
      return [...final, childImageSharp.gatsbyImageData];
    }, []);
  });
  const goToNextEngine = () => {
    if (timeline.isActive())
      return;
    let engineScale = 1;
    let slug = engines.nodes[activeSlide + 1].slug;
    slug === "fx1000v-efi" ? engineScale = 1.1 : engineScale = 1;
    slug === "fx850v" ? engineScale = 1.1 : engineScale = 1;
    timeline.to(slideRefs[activeSlide].current.wrapper.current, {
      x: "-=100%",
      transform: `scale(1)`,
      duration: slideDuration,
      ease: easing
    }, ">").to(slideRefs[activeSlide].current.wrapper.current, {
      opacity: 0,
      duration: slideDuration * 0.3,
      ease: easing
    }, "<").to(slideRefs[getDesiredActiveSlide(activeSlide + 1)].current.wrapper.current, {
      x: "-=100%",
      transform: `scale(${engineScale})`,
      duration: slideDuration,
      ease: easing
    }, "<").to(slideRefs[getDesiredActiveSlide(activeSlide + 1)].current.image.current, {
      transform: `scale(1)`,
      duration: slideDuration,
      delay: slideDuration * 0.2,
      ease: easing
    }, "<");
    engines.nodes.map((engine, i) => {
      if (i >= activeSlide + 2) {
        timeline.to(slideRefs[getDesiredActiveSlide(i)].current.wrapper.current, {
          x: "-=100%",
          duration: slideDuration,
          ease: easing
        }, "<");
      }
    });
    const nextSlide = getDesiredActiveSlide(activeSlide + 1);
    setSelectedEngine(engines.nodes[nextSlide]);
    setActiveSlide(nextSlide);
  };
  const goToPreviousEngine = () => {
    if (timeline.isActive())
      return;
    let engineScale = 1;
    let slug = engines.nodes[activeSlide - 1].slug;
    console.log(slug);
    slug === "fx1000v-efi" ? engineScale = 1.1 : engineScale = 1;
    slug === "fx850v" ? engineScale = 1.1 : engineScale = 1;
    timeline.to(slideRefs[getDesiredActiveSlide(activeSlide)].current.wrapper.current, {
      x: "+=100%",
      transform: "scale(0.7)",
      opacity: 1,
      duration: slideDuration,
      ease: easing
    }, ">").to(slideRefs[getDesiredActiveSlide(activeSlide - 1)].current.wrapper.current, {
      x: "+=100%",
      transform: `scale(${engineScale})`,
      duration: slideDuration
    }, "<").to(slideRefs[getDesiredActiveSlide(activeSlide - 1)].current.wrapper.current, {
      opacity: 1,
      duration: 0.5,
      ease: easing
    }, `>-0.7`);
    engines.nodes.map((engine, i) => {
      if (i >= activeSlide + 1) {
        timeline.to(slideRefs[getDesiredActiveSlide(i)].current.wrapper.current, {
          x: "+=100%",
          duration: slideDuration,
          easing
        }, "<");
      }
    });
    const nextSlide = getDesiredActiveSlide(activeSlide - 1);
    setSelectedEngine(engines.nodes[nextSlide]);
    setActiveSlide(nextSlide);
  };
  return /* @__PURE__ */ React.createElement(Page, __spreadProps(__spreadValues({}, props), {
    title: "Inside Kawasaki Engines | Engine Gallery",
    metaDescription: "Get up close and compare the innovative features that make an engine a Kawasaki."
  }), /* @__PURE__ */ React.createElement(Base, {
    as: "main",
    bg: "black",
    height: [`${viewportHeight - 58}px`, null, "calc(100vh - 104px)"],
    minHeight: ["calc(620px - 58px)", null, "calc(620px - 104px)"],
    overflow: "hidden"
  }, /* @__PURE__ */ React.createElement(Base, {
    as: "section",
    maxWidth: ["100%"],
    mx: "auto",
    height: "100%",
    display: ["grid", null, "grid"],
    gridTemplateColumns: [
      "100%",
      null,
      "1fr 1fr",
      "33% 1fr",
      null,
      "25% 1fr"
    ],
    gridTemplateRows: ["auto 1fr", null, "unset"],
    alignItems: ["start", null, "center"],
    position: "relative",
    padding: ["0 0", null, "0 20px", "0 32px", "0 0 0 100px"],
    zIndex: 1
  }, /* @__PURE__ */ React.createElement(Base, {
    position: "relative",
    height: ["auto", null, "100%"],
    as: "div",
    zIndex: 4
  }, engines.nodes.map((engine) => /* @__PURE__ */ React.createElement(EngineSummary, __spreadProps(__spreadValues({
    key: `${engine.slug}-summary`
  }, engine), {
    visible: isLoaded === true && engine.slug === selectedEngine.slug,
    onOpenClick: () => setIsMobileDescriptionOpen(true)
  })))), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    height: ["100%", "100%"],
    position: "relative",
    zIndex: 3
  }, /* @__PURE__ */ React.createElement(Base, {
    height: ["calc(100% - 48px)"],
    position: "absolute",
    top: "0",
    left: "0",
    width: "auto",
    as: "div",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch"
  }, engines.nodes.map(({ slug, initialCarouselImage, engineName }, i) => {
    return /* @__PURE__ */ React.createElement(EngineViewer, {
      engineName,
      ref: slideRefs[i],
      key: slug,
      images: getImagesBySlug(slug),
      pruneBy: 1,
      slug,
      isSelected: slug === selectedEngine.slug,
      handleClick: () => {
        if (slug !== selectedEngine.slug) {
          goToNextEngine();
        }
      }
    });
  })), /* @__PURE__ */ React.createElement(Base, {
    position: ["absolute", null, "fixed"],
    bottom: "0",
    left: "0",
    width: "100%"
  }, /* @__PURE__ */ React.createElement(CarouselNav, {
    loop: false,
    engines: engines.nodes,
    selectedEngine,
    onNext: () => {
      goToNextEngine();
    },
    onPrev: () => {
      goToPreviousEngine();
    }
  })), /* @__PURE__ */ React.createElement(MobileEngineDescription, {
    description: selectedEngine.carouselDescription,
    open: isMobileDescriptionOpen,
    onCloseClick: () => {
      setIsMobileDescriptionOpen(false);
    }
  })), /* @__PURE__ */ React.createElement(Base, {
    display: ["none", null, "initial"],
    as: BackgroundEngine,
    position: "absolute",
    top: "50%",
    left: "50%",
    style: { transform: `translate(-50%, -50%)` },
    zIndex: "1",
    opacity: "0.1"
  }))));
};
export default (props) => {
  const [progress, setProgress] = useState(0);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(LoadingScreen, {
    progress
  }), /* @__PURE__ */ React.createElement(Base, {
    position: "relative",
    zIndex: 1,
    as: "div"
  }, /* @__PURE__ */ React.createElement(Engines, __spreadProps(__spreadValues({}, props), {
    setLoadingProgress: (progressPct) => {
      setProgress(progressPct);
    }
  }))));
};
