import React from "react";
import { Text, Base } from "../../styles";
import styled from "styled-components";
const CloseButtonWrap = styled(Base)`
  color: white;
  transition: all 0.2s linear;
  &:hover {
    color: black;

    > div {
      background: black;

      > div {
        background: white;
      }
    }
  }
  > div {
    background: white;
    transition: all 0.2s linear;

    > div {
      background: black;
    }
  }
`;
export default ({
  description,
  open,
  onCloseClick
}) => {
  return /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    backgroundColor: "red",
    padding: ["32px"],
    position: "absolute",
    top: open ? "-46px" : "-100%",
    left: "0",
    width: "calc(100% - 64px)",
    height: "calc(100% - 64px + 46px)",
    zIndex: 3,
    textAlign: "center",
    display: [open ? "block" : "none", null, "none"]
  }, /* @__PURE__ */ React.createElement(Text, {
    variant: "small",
    color: "white",
    as: "div",
    maxWidth: "255px",
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  }, description), /* @__PURE__ */ React.createElement(CloseButtonWrap, {
    as: "button",
    onClick: onCloseClick,
    position: "absolute",
    top: "17px",
    right: "32px",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Text, {
    variant: "xx-small"
  }, "CLOSE"), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    width: "13px",
    height: "13px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ml: "7px"
  }, /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    height: "1px",
    width: "6px"
  }))));
};
