var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Base, Text } from "../../styles";
import styled from "styled-components";
import CTAButton from "./../CTAButton";
import { StyledGatsbyImage } from "../../styles";
import Page from "../Page";
import useEngineImageLoadProgress from "./useEngineImageLoadProgress";
const Copy = styled(Text)`
  text-align: center;
`;
const HomeContentGutter = styled(Base)`
  display: grid;
  justify-content: center;
  align-content: center;
  place-items: center;
`;
export default (props) => {
  const [isMounted, setIsMounted] = useState(false);
  const homePageImage = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "FX1000V-EFI_2-50_bigEngine.png"}) {
        childImageSharp {
          gatsbyImageData(
            width: 1080
            aspectRatio: 1
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
    }
  `);
  const homePageBackgroundImage = getImage(homePageImage.file);
  useEngineImageLoadProgress();
  useEffect(() => {
    setTimeout(() => setIsMounted(true), 100);
  }, []);
  return /* @__PURE__ */ React.createElement(Page, __spreadProps(__spreadValues({}, props), {
    title: "Welcome to the Kawasaki Virtual Experience | Virtual Experience",
    metaDescription: "Become immersed in what sets our engines apart from the competition.",
    path: props.path
  }), /* @__PURE__ */ React.createElement(Base, {
    as: "main",
    color: "white",
    bg: "#000",
    height: "100vh",
    minHeight: ["0", null, "600px"],
    position: "relative",
    zIndex: 2
  }, /* @__PURE__ */ React.createElement(StyledGatsbyImage, {
    alt: "Kawasaki Engines USA",
    image: homePageBackgroundImage,
    position: "absolute",
    bottom: "0",
    left: "50%",
    width: "100%",
    height: "100vh",
    maxWidth: "1440px",
    transform: "translateX(-50%)",
    opacity: 0.55,
    zIndex: 1
  }), /* @__PURE__ */ React.createElement(HomeContentGutter, {
    as: "div",
    p: ["58px 20px 0 20px", null, null, "104px 0 0 0"],
    position: "relative",
    zIndex: 2,
    maxHeight: "100%",
    height: ["calc(100% - 58px)", null, null, "calc(100% - 104px)"]
  }, /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    maxWidth: ["none", "725px", "725px"],
    position: "relative",
    textAlign: "center",
    transition: "top 0.5s ease-out",
    top: isMounted ? 0 : "-30px"
  }, /* @__PURE__ */ React.createElement(Text, {
    as: "h1",
    variant: ["large-bold", "x-large", "xx-large"],
    textTransform: "uppercase"
  }, "Performance", /* @__PURE__ */ React.createElement("br", null), "You Can Trust.")), /* @__PURE__ */ React.createElement(Copy, {
    as: "p",
    variant: ["x-small", "x-small", "medium"],
    maxWidth: ["none", "600px", "600px"],
    p: ["8px 0 16px", "16px 0 32px"],
    opacity: isMounted ? 1 : 0,
    transition: "opacity 0.5s ease-out 0.5s"
  }, "Discover what sets us apart through an immersive gallery of the features and technologies that make an engine a Kawasaki Engine."), /* @__PURE__ */ React.createElement(Base, {
    opacity: isMounted ? 1 : 0,
    transition: "opacity 0.5s ease-out 0.9s"
  }, /* @__PURE__ */ React.createElement(CTAButton, {
    backgroundStyle: "white-outline",
    link: {
      url: "/engines/",
      text: "Explore Engines"
    }
  })))), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    position: "fixed",
    top: "0",
    left: isMounted ? "-100vw" : 0,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    opacity: isMounted ? 0 : 1,
    zIndex: 3,
    transition: "opacity 0.5s ease-out 0.2s, left 0s ease-out 0.51s"
  }));
};
