var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { Router } from "@reach/router";
import Engines from "./Engines";
import Home from "./Home";
export default (props) => {
  return /* @__PURE__ */ React.createElement(Router, {
    basepath: "/"
  }, /* @__PURE__ */ React.createElement(Engines, __spreadProps(__spreadValues({}, props), {
    path: "/engines"
  })), /* @__PURE__ */ React.createElement(Home, __spreadProps(__spreadValues({}, props), {
    path: "/",
    animateMainNav: true
  })));
};
