import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useMemo
} from "react";
import { useDebounce } from "@react-hook/debounce";
import { Base, Text } from "../../styles";
import { getImage, getSrc } from "gatsby-plugin-image";
import memoize from "memoize-one";
import { useStaticQuery, graphql } from "gatsby";
import SVG from "../../../static/icons/360.svg";
import styled from "styled-components";
const Container = styled(Base)`
  display: grid;
  grid-gap: 12px;
  align-items: center;
  justify-content: center;
  grid-auto-flow: column;
  user-select: none;
`;
const getImageOrder = memoize((url) => {
  const { groups } = /-(?<order>\d{1,2})\.png/.exec(url);
  return parseInt(groups.order);
});
const RotationControlImage = (isActive) => {
  const rotationControlImage = useStaticQuery(graphql`
    query RotationControlImageQuery {
      file(name: {eq: "rotation-control"}) {
        childImageSharp {
          gatsbyImageData(width: 800)
        }
      }
    }
  `);
  const image = getImage(rotationControlImage.file);
  return /* @__PURE__ */ React.createElement(Container, {
    userSelect: "none",
    pointerEvents: "none",
    userDrag: "none",
    opacity: isActive.isActive === true ? "1" : "0",
    transition: "0.2s"
  }, /* @__PURE__ */ React.createElement("img", {
    src: SVG,
    height: "30px"
  }), /* @__PURE__ */ React.createElement(Text, {
    variant: ["xxx-small", "x-small"],
    color: "white"
  }, "DRAG TO ROTATE"));
};
const getNextSlide = memoize((nextSlide, totalSlides) => (nextSlide % totalSlides + totalSlides) % totalSlides);
export default forwardRef(({
  pruneBy = 3,
  images = [],
  slug,
  initialCarouselImage = 1,
  isSelected = false,
  engineName,
  handleClick
}, ref) => {
  const [currentSlide, setCurrentSlide] = useDebounce(0, 7);
  const [isActive, setActive] = useState(false);
  const [sortedImages, setSortedImages] = useState([]);
  const [lastTouchX, setLastTouchX] = useState(null);
  const wrapper = useRef(null);
  const image = useRef(null);
  useImperativeHandle(ref, () => ({
    wrapper,
    image
  }));
  useEffect(() => {
    images.sort((a, b) => {
      const imgA = getSrc(a);
      const imgB = getSrc(b);
      const orderA = getImageOrder(imgA);
      const orderB = getImageOrder(imgB);
      return orderA - orderB;
    });
    const finalImages = images.filter((img, i) => {
      if (i === 0 || i === images.length - 1)
        return true;
      return i % pruneBy === 0;
    });
    setSortedImages(finalImages);
    setCurrentSlide(Math.floor(initialCarouselImage / images.length * finalImages.length));
  }, []);
  const currentImage = useMemo(() => {
    const img = sortedImages && getImage(sortedImages[currentSlide]);
    return getSrc(img);
  }, [currentSlide, sortedImages]);
  function rotateLeft() {
    let nextSlide = getNextSlide(currentSlide - 1, sortedImages.length);
    setCurrentSlide(nextSlide);
  }
  function rotateRight() {
    let nextSlide = getNextSlide(currentSlide + 1, sortedImages.length);
    setCurrentSlide(nextSlide);
  }
  return /* @__PURE__ */ React.createElement(Base, {
    "data-slug": slug,
    ref: wrapper,
    as: "div",
    tabIndex: 0,
    opacity: 1,
    position: "relative",
    width: [
      "100vw",
      null,
      "calc(50vw - 30px)",
      "calc(100vw - 350px - 30px)",
      "44.3vw"
    ],
    height: "100%",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    transform: "scale(1.1)",
    onKeyDown: (e) => {
      if (e.key === "ArrowLeft") {
        rotateLeft();
      }
      if (e.key === "ArrowRight") {
        rotateRight();
      }
    }
  }, currentImage && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Base, {
    transform: "scale(0.7)",
    ref: image,
    as: "img",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "calc(100% - 100px)",
    objectFit: "contain",
    objectPosition: "center",
    opacity: 1,
    zIndex: 1,
    src: currentImage,
    userSelect: "none",
    userDrag: "none",
    alt: `Rotate the ${engineName}`,
    cursor: isSelected ? "ew-resize" : "unset",
    onTouchStart: (e) => {
      isSelected && setActive(true);
    },
    onTouchMove: (e) => {
      const currentX = e.touches[0].clientX;
      if (lastTouchX === null || !isActive) {
        setLastTouchX(currentX);
        return;
      }
      const distance = currentX - lastTouchX;
      if (distance > 0) {
        rotateLeft();
      }
      if (distance < 0) {
        rotateRight();
      }
      setLastTouchX(currentX);
    },
    onTouchEnd: () => {
      setActive(false);
    },
    onMouseLeave: () => setActive(false),
    onMouseDown: () => isSelected && setActive(true),
    onMouseUp: () => setActive(false),
    onMouseMove: (e) => {
      const { movementX } = e;
      if (!isActive)
        return;
      if (movementX > 0) {
        rotateLeft();
      }
      if (movementX < 0) {
        rotateRight();
      }
    },
    onClick: handleClick
  }), /* @__PURE__ */ React.createElement(RotationControlImage, {
    isActive: isSelected
  })));
});
