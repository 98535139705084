import React from "react";
import { Base, Text, StyledGatsbyImage } from "../../styles";
import CTAButton from "../CTAButton";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import styled from "styled-components";
const PlusIcon = () => {
  const image = useStaticQuery(graphql`
    query PlusIconImageQuery {
      file(name: {eq: "plus-icon"}) {
        childImageSharp {
          gatsbyImageData(width: 800)
        }
      }
    }
  `);
  const img = getImage(image.file);
  return /* @__PURE__ */ React.createElement(StyledGatsbyImage, {
    image: img,
    alt: "You can rotate this image",
    maxWidth: "11px",
    maxHeight: "11px"
  });
};
const SummaryWrapper = styled(Base)`
  &:first-child {
    position: relative;
  }
`;
export default ({
  engineName,
  horsePower,
  rpm,
  carouselDescription,
  slug,
  visible = false,
  onOpenClick
}) => {
  return /* @__PURE__ */ React.createElement(SummaryWrapper, {
    as: "div",
    maxWidth: ["100%", null, null, "350px"],
    width: "100%",
    position: "absolute",
    top: [0, null, "50%"],
    zIndex: visible ? 2 : 1,
    transition: "left .5s ease-out, opacity .5s ease-out, z-index 0s linear .5s",
    opacity: visible ? "1" : "0",
    left: visible ? "0" : "-15%",
    transform: ["none", null, "translateY(-50%)"]
  }, /* @__PURE__ */ React.createElement(Text, {
    as: "h2",
    variant: ["large-bold", "x-large-bold"],
    color: "white",
    mb: [20],
    textAlign: ["center", null, "left"]
  }, engineName), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    display: "flex",
    mb: [20],
    alignItems: "center",
    justifyContent: ["center", null, "flex-start"]
  }, /* @__PURE__ */ React.createElement(Text, {
    variant: ["medium-bold", null, "large-bold"],
    color: "red"
  }, horsePower, " HP"), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    width: "1px",
    height: ["22px", null, "35px"],
    bg: "#626262",
    mx: "12px"
  }), /* @__PURE__ */ React.createElement(Text, {
    variant: ["medium-bold", null, "large-bold"],
    color: "red"
  }, rpm, " RPM")), /* @__PURE__ */ React.createElement(Text, {
    as: "p",
    color: "white",
    variant: "small",
    mb: [40],
    display: ["none", null, "block"]
  }, carouselDescription), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    display: ["flex", null, "block"],
    justifyContent: "center",
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Base, {
    as: "button",
    color: "white",
    textTransform: "uppercase",
    backgroundColor: "transparent",
    border: "none",
    mr: "16px",
    cursor: "pointer",
    display: ["block", null, "none"],
    onClick: onOpenClick
  }, /* @__PURE__ */ React.createElement(Text, {
    display: "inline-block",
    marginRight: "7px",
    variant: ["xx-small"],
    cursor: "pointer"
  }, "More Info"), /* @__PURE__ */ React.createElement(PlusIcon, null)), /* @__PURE__ */ React.createElement(CTAButton, {
    backgroundStyle: "white-outline",
    link: {
      text: "Explore",
      url: `/engines/${slug}`
    }
  })));
};
