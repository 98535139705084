import React, { useEffect, useState } from "react";
import { Base, Text } from "../styles";
import Logo from "../images/logo.svg";
import styled from "styled-components";
const Diagonals = ({ progress }) => {
  const threshold = Math.floor(progress / 100 * 30);
  return /* @__PURE__ */ React.createElement(Base, {
    width: "100%",
    height: "100%",
    as: "div",
    display: "flex"
  }, [...Array(30).keys()].map((num, i) => /* @__PURE__ */ React.createElement(Base, {
    key: i,
    as: "div",
    bg: i < threshold ? "red" : "white",
    height: "100%",
    width: "2px",
    style: { transform: "rotate(40deg)" },
    flex: "0 0 auto",
    mr: "4px",
    position: "relative",
    top: "-8px"
  })));
};
const LoadingScreenWrap = styled(Base)`
  height: 100%;
  height: -webkit-fill-available;
`;
export default ({ progress }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [progress]);
  return /* @__PURE__ */ React.createElement(LoadingScreenWrap, {
    "data-name": "LoadingScreenWrap",
    bg: "black",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: isLoading ? 10 : -1,
    transition: "opacity 1s linear, z-index 0s linear 2s",
    opacity: isLoading ? 1 : 0
  }, /* @__PURE__ */ React.createElement(Base, {
    width: ["182px", null, "273px"],
    height: ["48px", null, "73px"],
    as: "div",
    mb: "40px"
  }, /* @__PURE__ */ React.createElement(Logo, null)), /* @__PURE__ */ React.createElement(Text, {
    as: "div",
    variant: "large-bold",
    color: "white",
    mb: "20px"
  }, progress, "%"), /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    position: "relative",
    width: "200px",
    height: "50px"
  }, /* @__PURE__ */ React.createElement(Diagonals, {
    progress
  })));
};
