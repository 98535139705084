import { useMemo } from "react";
import useLoadProgress from "./useLoadProgress";
import { getSrc } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
export default () => {
  const { engineImages } = useStaticQuery(graphql`
    query EngineImagesQuery {
      engineImages: allFile(
          filter: {
            sourceInstanceName: {eq: "images"}
            relativeDirectory: {regex: "/^engines/"}
            name: {regex: "/\\d[1,3,5,7,9]$/"}
          }
      ) {
        nodes {
          relativeDirectory
          sourceInstanceName
          childImageSharp {
            gatsbyImageData(width: 800)
          }
        }
      }
    }
  `);
  const imagePaths = useMemo(() => {
    return engineImages.nodes.map((n) => getSrc(n.childImageSharp.gatsbyImageData));
  }, [engineImages]);
  const progress = useLoadProgress(imagePaths);
  return progress;
};
